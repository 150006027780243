import React, { Component } from "react";

class Bio extends Component {
  render() {
    return (
      <div className={`bio-container pb-8 border-b-2 border-black`}>
        <h2 className={`price-list-heading text-3xl text-black font-bold`}>
          Software Engineer.
        </h2>
        <p className={`bio-paragraph mb-5 text-lg text-justify`}>
          Hi, I'm Cory.
        </p>
        <p className={`bio-paragraph mb-5 text-lg text-justify`}>
          I'm a Sr Software Engineering Manager based in San Diego County. I
          program in a variety of languages with most experience in the areas of
          Javascript, Typescript, PHP, Go, and Rust. Other than programming, I
          enjoy SysOps, task automation, server configuration, and more. Outside
          of work, I love to get outside and enjoy what California has to offer
          like running, tennis, surfing, soccer, live music, and wine tasting.
        </p>
        <p className={`bio-paragraph mb-5 text-lg text-justify`}>
          I grew up in Boulder and spent the majority of the first 30 years of
          my life in Colorado. While pursuing a financial analyst career path
          that didn't serve my internal need for creativity and building direct
          solutions to the business issues I was seeing regularly, I dedicated
          countless hours outside of work to building a new set of skills. I
          found a renewed sense of excitement from expanding my knowledge in
          systems operations and management, software architecture and
          engineering, and especially on how business needs and web technologies
          could be integrated. In addition to business needs, I'm pursuing ideas
          for how to make technology more accessible to philanthropic
          organizations and smaller businesses that serve the needs within our
          local communities, regions and across the globe.
        </p>
        <p className={`bio-paragraph mb-5 text-lg text-justify`}>
          Of course I have my opinions; however, I'm of the belief that there
          are a wide variety of technologies that offer great value and business
          and technical requirements should largely drive what is selected for
          the underlying technical implementations. With that said, shown above
          are a few of the technologies, platforms, languages and frameworks
          that I have most enjoyed working with thus far in the decade or more
          since I moved into the software engineering space.
        </p>
        <p className={`bio-paragraph mb-5 text-lg text-justify`}>
          I am presently working a full-time role helping deliver a full-feature
          ecommerce ecosystem for local business owners across the US. However,
          I am open to speaking about opportunities to work with you on projects
          where remote and flexible work hours might be a possibility. Please
          reach out{" "}
          <a href={`#contact`} className={`bio-contact-link`} target={`_self`}>
            below
          </a>{" "}
          and I will respond with additional detail at the earliest opportunity.
          Thanks and I look forward to hearing from you.
        </p>
      </div>
    );
  }
}

export default Bio;

import React, {useEffect} from 'react';
import Skills from './components/Skills';
import Bio from './components/Bio';
// import Pricing from './components/Pricing';
import Seo from './components/Seo';
import Contact from './components/Contact';
import Header from './components/Header';
import Footer from './components/Footer';

// images and CSS
// import backgroundImage from './dominik-schroder-FIKD9t5_5zQ-unsplash.jpg';
import 'tailwindcss/dist/tailwind.min.css';
import './App.css';

// fontawesome
import {fab, faJs} from '@fortawesome/free-brands-svg-icons';
import {
  faAsterisk,
  faBars,
  faBolt,
  faCloud,
  faCloudSunRain,
  faFileCode,
  faFingerprint,
  faKey,
  faServer,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(
  fab,
  faBolt,
  faBars,
  faCloudSunRain,
  faAsterisk,
  faTimes,
  faKey,
  faJs,
  faFileCode,
  faServer,
  faCloud,
  faFingerprint
);

/**
 * @return {boolean}
 */
function App() {
  // const [backgroundStyles, setBackgroundStyles] = useState({});

  useEffect(() => {
    // set background image after load.
    // const loadImage = new Image();
    // loadImage.onload = () => {
    //   setBackgroundStyles({
    //     background: `url(${backgroundImage}) no-repeat top center`,
    //     backgroundSize: 'cover'
    //   });
    // };
    //
    // loadImage.src = `${backgroundImage}`;
  }, []);

  return (
    // <div className="app" style={backgroundStyles}>
    <div className="app">
      <section id={`header-container`} className={`pt-6 px-6`}>
        <Header/>
      </section>

      <section id={`skills-container`} className={`px-6`}>
        <Skills/>
      </section>

      <section id={`bio-pricing-container`} className={`md:p-6`}>
        <Bio/>
        {/*<Pricing/>*/}
      </section>

      <section id={`seo-container`} className={`p-8 bg-white`}>
        <Seo/>
      </section>

      <section id={`footer-container`} className={``}>
        <Footer/>
      </section>

      <section id={`contact-form-container`} className={`p-6 pb-24 bg-gray-900`}>
        <Contact/>
      </section>
    </div>
  );
}

export default App;

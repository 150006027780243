import React, {ChangeEvent, Component, FocusEvent, FormEvent} from 'react';

interface ContactFormData {
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    message: string,
    response: string,
}

class Contact extends Component<{}, ContactFormData> {

    constructor(props: object) {
        super(props);

        this.moveLabel = this.moveLabel.bind(this);
        this.moveTextAreaLabel = this.moveTextAreaLabel.bind(this);
        this.validSubmit = this.validSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.getSuccessState = this.getSuccessState.bind(this);
        this.getErrorState = this.getErrorState.bind(this);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            message: '',
            response: 'hidden',
        };
    }

    getSuccessState() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            message: '',
            response: 'success',
        };
    }

    getErrorState() {
        return {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
            response: 'error',
        };
    }

    moveLabel(e: FocusEvent<HTMLInputElement>): void {
        let currentLabel = e.target.labels![0];
        currentLabel.style.top = '2px';
        currentLabel.style.fontSize = '10px';
        currentLabel.style.textTransform = 'uppercase';
        currentLabel.style.fontWeight = '700';
    }

    moveTextAreaLabel(e: FocusEvent<HTMLTextAreaElement>): void {
        let currentLabel = e.target.labels![0];
        currentLabel.style.top = '22px';
        currentLabel.style.fontSize = '10px';
        currentLabel.style.textTransform = 'uppercase';
        currentLabel.style.fontWeight = '700';
    }

    validSubmit(formData: ContactFormData): boolean {
        return !(!formData.first_name ||
            !formData.last_name ||
            !formData.email ||
            !formData.phone ||
            !formData.message);
    }

    handleChange(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({
            ...this.state,
            [e.target.name!]: e.target.value
        });
    }

    handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        this.setState({
            ...this.state,
            [e.target.name!]: e.target.value
        });
    }

    async handleSubmit(e: FormEvent): Promise<boolean> {
        e.preventDefault();
        let contactUrl = `${process.env.REACT_APP_BACKEND_API}/contact`;
        let formData = {...this.state};

        // 1. quick validation
        if (!this.validSubmit(formData)) {

            // display error and return false
            let emptyInputNames: string[] = [];
            Object.entries(formData).forEach((input) => {
                if (!input[1]) {
                    switch (input[0]) {
                        case 'first_name':
                            emptyInputNames.push('first_name');
                            break;
                        case 'last_name':
                            emptyInputNames.push('last_name');
                            break;
                        default:
                            emptyInputNames.push(input[0]);
                    }
                }
            });

            // make submit button red and add "x"
            emptyInputNames.forEach((emptyInputName) => {
                let errorInput: HTMLInputElement = document.querySelector(`#${emptyInputName}`) as HTMLInputElement;
                errorInput.className = errorInput.className! + ' input-error';
            });

            return false;
        } else {
            // TODO: make submit button green and add check mark
        }

        try {
            let requestBody = JSON.stringify({
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                phone: formData.phone,
                message: formData.message,
            });

            // 2. use fetch to POST to Rust backend, if error, return false / display error
            const response = await fetch(contactUrl, {
                method: 'POST',
                // mode: 'no-cors',
                // cache: 'no-cache',
                // credentials: 'same-origin',
                headers: {'Content-Type': 'application/json'},
                // redirect: 'follow',
                // referrerPolicy: 'no-referrer',
                body: requestBody
            });

            const formResponse = await response.json();
            if (!formResponse.success) {
                throw new Error('Failed to submit form.');
            }

            // 3. clear form / display success.
            this.setState(this.getSuccessState());
        } catch (err) {
            console.error(err);
            this.setState(this.getErrorState());
        }

        return false;
    }

    render() {
        return (
            <div>
                <h2 className={`contact-form-heading text-4xl text-center text-white font-bold`}>Reach Out.</h2>
                <form className={`contact-form`} onSubmit={this.handleSubmit} autoComplete={`off`}>
                    <div className={`form-input-wrapper`}>
                        <div className="form-input-group">
                            <label htmlFor="first_name" className={`text-white`}>First Name</label>
                            <input className={`px-5 py-4 text-white bg-gray-900 hover:bg-gray-800`}
                                   id={`first_name`}
                                   name={`first_name`}
                                   type={`text`}
                                   value={this.state.first_name}
                                   onFocus={this.moveLabel}
                                   onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-input-group">
                            <label htmlFor="last_name" className={`text-white`}>Last Name</label>
                            <input className={`px-5 py-4 text-white bg-gray-900 hover:bg-gray-800`}
                                   id={`last_name`}
                                   name={`last_name`}
                                   type={`text`}
                                   value={this.state.last_name}
                                   onFocus={this.moveLabel}
                                   onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-input-group">
                            <label htmlFor="email" className={`text-white`}>Email</label>
                            <input className={`px-5 py-4 text-white bg-gray-900 hover:bg-gray-800`}
                                   id={`email`}
                                   name={`email`}
                                   type={`email`}
                                   value={this.state.email}
                                   onFocus={this.moveLabel}
                                   onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-input-group">
                            <label htmlFor="phone" className={`text-white`}>Phone</label>
                            <input className={`px-5 py-4 text-white bg-gray-900 hover:bg-gray-800`}
                                   id={`phone`}
                                   name={`phone`}
                                   type={`tel`}
                                   value={this.state.phone}
                                   onFocus={this.moveLabel}
                                   onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-input-group-textarea">
                        <label htmlFor="message" className={`text-white`}>Message</label>
                        <textarea className={`my-5 px-5 py-4 text-white bg-gray-900 hover:bg-gray-800`}
                                  id={`message`}
                                  name={`message`}
                                  cols={30}
                                  rows={5}
                                  value={this.state.message}
                                  onFocus={this.moveTextAreaLabel}
                                  onChange={this.handleTextAreaChange}
                        />
                    </div>
                    <div className={`form-input-wrapper`}>
                        <div className="form-submit">
                            <input className={`px-5 py-4 text-sm font-bold bg-blue-500 hover:bg-blue-400`}
                                   id={`contact_submit`}
                                   name={`contact_submit`}
                                   type="submit"
                                   value={`Send`}
                            />
                        </div>
                        <div className={`form-input-group`}>
                            <div id={`success-wrapper`} className={this.state.response !== 'success' ? `hidden` : ``}>
                                <div id={`response-success`} className={`response py-5 px-10 text-sm text-white font-bold border-green-600 border-2 bg-green-900 rounded`}>Sent
                                successfully! I will reach out at the earliest opportunity. Thank you for your interest.</div>
                            </div>
                            <div id={`error-wrapper`} className={this.state.response !== 'error' ? `hidden` : ``}>
                                <div id={`response-error`} className={`response py-5 px-10 text-sm text-white font-bold border-red-600 border-2 bg-red-900 rounded`}>So sorry,
                                there was a failure sending your message. Please feel free to reach out directly at (303) 263 3737. Thank you for your interest.</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Contact;
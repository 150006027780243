import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Skills extends Component {
  render() {
    return (
      <ul className={`skills pb-8 border-b-2 border-black`}>
        <li className={`skill-set`}>
          <div className={`mr-5`}>
            <FontAwesomeIcon
              className={`mt-8 text-black`}
              icon={`bolt`}
              size={`2x`}
            />
          </div>
          <div className={`skill-detail`}>
            <h3 className={`skill-heading text-2xl font-semibold`}>
              Javascript
            </h3>
            <p className={`skill text-sm font-medium`}>
              TypeScript. React. Node.js. Fastify. Express. JQuery.
            </p>
          </div>
        </li>
        <li className={`skill-set`}>
          <div className={`mr-5`}>
            <FontAwesomeIcon
              className={`mt-8 text-black`}
              icon={`server`}
              size={`2x`}
            />
          </div>
          <div className={`skill-detail`}>
            <h3 className={`skill-heading text-2xl font-semibold`}>
              Server-side
            </h3>
            <p className={`skill text-sm font-medium`}>
              API Development. Go. Fiber. Gin. Revel. Rust. Actix Web. Rocket.
              PHP. Magento. Laravel. SQL. MongoDB.
            </p>
          </div>
        </li>
        <li className={`skill-set`}>
          <div className={`mr-5`}>
            <FontAwesomeIcon
              className={`mt-8 text-black`}
              icon={`file-code`}
              size={`2x`}
            />
          </div>
          <div className={`skill-detail`}>
            <h3 className={`skill-heading text-2xl font-semibold`}>Frontend</h3>
            <p className={`skill text-sm font-medium`}>
              Webpack. SASS / SCSS. CSS. HTML. SEO practices.
            </p>
          </div>
        </li>
        <li className={`skill-set`}>
          <div className={`mr-5`}>
            <FontAwesomeIcon
              className={`mt-8 text-black`}
              icon={`cloud`}
              size={`2x`}
            />
          </div>
          <div className={`skill-detail`}>
            <h3 className={`skill-heading text-2xl font-semibold`}>SysOps</h3>
            <p className={`skill text-sm font-medium`}>
              AWS. Google Cloud. Digital Ocean. Linux. Ubuntu. GitHub. BASH.
              Ansible. Nginx. Jenkins. Rundeck. Systemd.
            </p>
          </div>
        </li>
      </ul>
    );
  }
}

export default Skills;

import React, {Component} from 'react';
import Weather from "./Weather";

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      photographer: {
        name: 'Dominik Schröder',
        link: '//unsplash.com/@wirhabenzeit'
      },
      copyright: {
        year: (new Date()).getFullYear(),
        entity: 'Cory R Hughes'
      },
      location: {
        city: "San Diego",
        state: "CA"
      }
    };
  }

  render() {
    return (
      <footer className={`footer-component flex items-end justify-between bg-blue-200 p-5`}>
        <Weather location={this.state.location} />
        {/*<div className={`photographer-credit p-2 text-center`}>*/}
        {/*  <p className={`text-sm my-5`}>Photo by*/}
        {/*    <a href={`${this.state.photographer.link}`}*/}
        {/*       className={`mx-2 font-bold`}*/}
        {/*       target="_blank"*/}
        {/*       rel="noopener noreferrer">{this.state.photographer.name}</a>*/}
        {/*    on Unsplash</p>*/}
        {/*</div>*/}
        <div className={`copyright p-2 text-right`}>
          <div id={`contact-info-footer`} className={`max-w-sm text-xs text-left grid grid-cols-2 gap-1`}>
            <div id={`footer-location`} className={`font-bold text-left`}>Location</div>
            <div>{this.state.location.city}, {this.state.location.state}</div>
            <div id={`footer-phone`} className={`font-bold text-left`}>Phone</div>
            <div>(303) 263 3737</div>
            <div id={`footer-email`} className={`font-bold text-left`}>Email</div>
            <div>cory@coryrhughes.com</div>
          </div>
          <p id={`copyright-statement`} className={`text-center mt-5 text-sm font-bold`}>&copy; {this.state.copyright.year}.&nbsp;&nbsp;
            {this.state.copyright.entity}.&nbsp;&nbsp;All rights reserved.</p>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {};

export default Footer;
import React, {Component} from 'react';

class Seo extends Component {
  render() {
    return (
      <div className={`seo-inner-container`}>
        <div className={`seo-group`}>
          <h3 className={`seo-heading text-3xl text-black`}>Local Market | San Diego.</h3>
          <ul className={`seo-list`}>
            <li>Del Mar</li>
            <li>Encinitas</li>
            <li>Rancho Santa Fe</li>
            <li>Carlsbad</li>
            <li>La Jolla</li>
            <li>Carmel Valley</li>
            <li>Point Loma</li>
            <li>Coronado</li>
            <li>North Park</li>
            <li>Gaslamp</li>
            <li>San Clemente</li>
          </ul>
        </div>
        <div className={`seo-group`}>
          <h3 className={`seo-heading text-3xl text-black`}>Remote Markets.</h3>
          <div className={`seo-remote-markets-group`}>
            <ul className={`seo-list`}>
              <li>Orange County</li>
              <li>Laguna Beach</li>
              <li>Newport</li>
              <li>Irvine</li>
              <li>Los Angeles</li>
              <li>Santa Monica</li>
              <li>Marina Del Rey</li>
              <li>Venice</li>
              <li>Los Feliz</li>
              <li>San Francisco</li>
              <li>San Jose</li>
              <li>Palo Alto</li>
              <li>Mountain View</li>
              <li>Napa Valley</li>
              <li>Palm Springs</li>
            </ul>
            <ul className={`seo-list`}>
              <li>Chicago</li>
              <li>Seattle</li>
              <li>Austin</li>
              <li>Santa Barbara</li>
              <li>Portland</li>
              <li>Boulder</li>
              <li>Denver</li>
              <li>London</li>
              <li>Sydney</li>
              <li>Rio De Janiero</li>
              <li>Buenos Aires</li>
              <li>Mexico City</li>
              <li>Hong Kong</li>
            </ul>
          </div>
        </div>
        <div className={`seo-group`}>
          <h3 className={`seo-heading text-3xl text-black`}>Services.</h3>
          <ul className={`seo-list`}>
            <li>Web Application Architecture and Development</li>
            <li>System Infrastructure Planning</li>
            <li>Security Analysis</li>
            <li>General Consulting</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Seo;

import React, {Component} from 'react';
import Menu from "./Menu";
import logo from "../coryrhughes.logo.2019.svg";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <header className="app-header mb-8">
        <div className={`app-header-item`}>
          <img src={logo} className="app-logo" alt="logo" />
          <h1 className={`page-heading text-3xl text-center ml-5 font-bold`}>Cory Hughes.</h1>
        </div>

        <Menu />
      </header>
    );
  }
}

export default Header;
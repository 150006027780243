import React, {Component} from 'react';
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Weather extends Component {

  constructor(props) {
    super(props);

    this.state = {
      weather: {
        coord: {
          lat: 0,
          lon: 0
        },
        weather: [
          {
            id: 0,
            main: "",
            description: "",
            icon: ""
          }
        ],
        base: "",
        main: {
          temp: 0,
          pressure: 0,
          humidity: 0,
          temp_min: 0,
          temp_max: 0
        },
        visibility: 0,
        clouds: {
          all: 0
        },
        dt: 0,
        sys: {
          id: 0,
          country: "",
          sunrise: 0,
          sunset: 0
        },
        timezone: 0,
        id: 5346646,
        name: "",
        cod: 0
      },
    };

    this.kelvinToFahrenheit = this.kelvinToFahrenheit.bind(this);
  }

  kelvinToFahrenheit(kelvin) {
    return (kelvin - 273.15) * 9 / 5 + 32;
  }

  componentDidMount() {
    // fetch weather from backend and update state.
    let weatherUrl = `${process.env.REACT_APP_BACKEND_API}/weather`;
    fetch(weatherUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.cod === 200) {
          this.setState({weather: data});
          document.querySelector("#weather-component").className = '';
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  render() {
    return (
      <div id={`weather-component`} className={`hidden`}>
        <div id={`location`} className={`text-xl text-white font-bold`}>{this.props.location.city.toUpperCase()}, {this.props.location.state.toUpperCase()}</div>
        <div id={`weather-highlights`} className={`mb-5 flex justify-between items-center`}>
          <div id={`current-conditions`} className={`flex flex-col justify-center items-center`}>
            <img src={`//openweathermap.org/img/wn/${this.state.weather.weather[0].icon}@2x.png`}
                 alt={`weather icon`}/>
            {/*<div id={`sky-desc`} className={``}>({this.state.weather.weather[0].main.toLowerCase()})</div>*/}
          </div>
          <div id={`temp-wrapper`} className={``}>
            <div id={`temperature`} className={`text-4xl font-bold`}>{Math.round(
              this.kelvinToFahrenheit(this.state.weather.main.temp)*10)/10}° <span
              id={`temperature-scale`} className={`text-lg font-bold`}>Fahrenheit</span></div>
          </div>
        </div>

        <div id={`sunrise-sunset`} className={`text-xs`}>
          <span className={`font-bold`}>Sunrise</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{new
          Date( this.state.weather.sys.sunrise * 1000).toLocaleTimeString()}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
          className={`font-bold`}>Sunset</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{new
          Date(this.state.weather.sys.sunset * 1000).toLocaleTimeString()}</div>
      </div>
    );
  }
}

export default Weather;